import React, { useState } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

import { fields, buttons, dialogs } from '../app/thema';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';
import { urlHome } from '../common/helper/UrlRouter';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import {
    RegPersonnelAsync, FindByTokenNumberAsync, FindByCallSignAsync
} from '../common/api/Personnel';
import { MilitaryHandbooksAsync, Handbook300Async } from '../common/api/Handbook';
import { GetTraumaAsync, RegTraumaAsync, UpdTraumaAsync } from '../common/api/RegForms';

import { Grid, Card, Typography } from '@mui/material';

import { DateFld, DateTimeFld, DateTimeDesktopFld, MultilineFld } from '../components/Fields';
import { AutoCompleteCbx } from '../components/ComboBoxs';
import { StartIconBtn, EndIconBtn } from '../components/Buttons';
import { CircularRightPrg } from '../components/Progress';
import { PersonnalDlg } from '../components/Dialogs';

import { TokenNumber } from './TokenNumber';
import { CallSign } from './CallSign';
import { FullNameInput } from './FullNameInput';
import { FullNameShow } from './FullNameShow';
import { ComboBoxEdit } from './ComboBoxEdit';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import typeOfDirectionJson from '../shared/typeOfDirection.json';
import specialistJson from '../shared/specialist.json';


/**
 * Виджет формы регистрации пациента соматика
 * @returns виджет
 */
export default function FormaByPatientDiseases() {
    const navigate = useNavigate();

    const [tokenNumberOk, setTokenNumberOk] = useState(false);
    const [isVisibleCallSign, setIsVisibleCallSign] = useState(true);
    const [callSignOk, setCallSignOk] = useState(false);
    const [isVisibleFullName, setisVisibleFullName] = useState(false);
    const [FullNameValue, setFullNameValue] = useState('');
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [personnalDlgType, setPersonnalDlgType] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [typeOfDirection, setTypeOfDirection] = useState(typeOfDirectionJson[0]);
    const [specialist, setSpecialist] = useState(specialistJson[0]);
    const [personnels, setPersonnels] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [idEvent, setIdEvent] = useState('');
    const [idHistory, setIdHistory] = useState('');
    let isValid = true;
    let isOnline = true;

    const [militaryPartJson, setMilitaryPartJson] = useState();
    var militaryPartList = [];
    const [militaryUnitJson, setMilitaryUnitJson] = useState();
    var militaryUnitList = [];
    const [militaryCompanyJson, setMilitaryCompanyJson] = useState();
    var militaryCompanyList = [];
    const [militaryPlatoonJson, setMilitaryPlatoonJson] = useState();
    var militaryPlatoonList = [];
    const [militaryDepartmentJson, setMilitaryDepartmentJson] = useState();
    var militaryDepartmentList = [];
    const [militaryRankJson, setMilitaryRankJson] = useState();
    var militaryRankList = [];
    const [locationMedicalAssistanceJson, setLocationMedicalAssistanceJson] = useState();
    var locationMedicalAssistanceList = [];
    const [locationWhereJson, setLocationWhereJson] = useState();
    var locationWhereList = [];

    const [militaryPart, setMilitaryPart] = useState({ id: '', label: '' });
    const [militaryUnit, setMilitaryUnit] = useState({ id: '', label: '' });
    const [militaryCompany, setMilitaryCompany] = useState({ id: '', label: '' });
    const [militaryPlatoon, setMilitaryPlatoon] = useState({ id: '', label: '' });
    const [militaryDepartment, setMilitaryDepartment] = useState({ id: '', label: '' });
    const [militaryRank, setMilitaryRank] = useState({ id: '', label: '' });
    const [locationMedicalAssistance, setLocationMedicalAssistance] = useState({ id: '', label: '' });
    const [locationWhere, setLocationWhere] = useState({ id: '', label: '' });

    const [isEditMilitaryPath, setIsEditMilitaryPath] = useState(false);
    const [isEditMilitaryUnit, setIsEditMilitaryUnit] = useState(false);
    const [isEditMilitaryCompany, setIsEditMilitaryCompany] = useState(false);
    const [isEditMilitaryPlatoon, setIsEditMilitaryPlatoon] = useState(false);
    const [isEditMilitaryDepartment, setIsEditMilitaryDepartment] = useState(false);
    const [isEditMilitaryRank, setIsEditMilitaryRank] = useState(false);
    const [isEditLocationMedicalAssistance, setIsEditLocationMedicalAssistance] = useState(false);
    const [isEditLocationWhere, setIsEditLocationWhere] = useState(false);


    const handleDataActionChange = (e) => {
        formik.setFieldValue(model.dateAction.name, e.target.value);
    }

    const handleSetBirthday = (val) => {
        let year = (new Date().getFullYear()) - 30;
        let dt = new Date(year, 1).toISOString();
        dt = dt.substring(0, dt.indexOf('T'));
        formik.setFieldValue(model.birthday.name, dt);
    }

    const handleSetDataAction = (val) => {
        let dt = new Date().toISOString();
        // Валидация
        if (val != null || val != undefined) {
            let tmp = new Date(val);
            dt = tmp.getFullYear() === 1 ? dt : new Date(val).toISOString();
        }
        formik.setFieldValue(model.dateAction.name, dt);
    }

    const handleSetDateOfEvacuation = (val) => {
        let isValid = dayjs(val).isValid();
        if (isValid) {
            let tmp = new Date(val);
            val = tmp.getFullYear() === 1 ? val : new Date(val).toISOString();
        }
        formik.setFieldValue(model.dateOfEvacuation.name, val);
    }

    // События поля номер жетона
    const isTokenNumber = (fl) => {
        if (fl) {
            // Номер жетона был найден
            setTokenNumberOk(true);
            setIsVisibleCallSign(false);
            setCallSignOk(false);
            setisVisibleFullName(false);
            setStep(2);

            return;
        }

        // Номер жетона не был найден
        setTokenNumberOk(false);
        setIsVisibleCallSign(true);
        setCallSignOk(false);
        setStep(1);
    }
    const handleTokenNumberKeyUp = async (e) => {
        if (e.keyCode !== 13) return;

        handleNextClick();
    }
    const handleTokenNumberClick = () => {
        setTokenNumberOk(false);
        setStep(0);
    }
    const handleTokenNumber = async () => {
        handleSetBirthday();
        handleSetDataAction();
        let res = await FindByTokenNumberAsync(formik.values.tokenNumber);
        // Валидация запроса
        if (!IsCode200(res)) {
            isTokenNumber(false);
            setLoading(false);

            return;
        }

        setPersonnels(res.result);
        if (res.result.length === 1) {
            setSelectedValue(res.result[0]);
            setFullNameValue(`${res.result[0].fullName}`);
            await handleFillData(res.result[0].id);
            isTokenNumber(true);
            setLoading(false);

            return;
        }

        setPersonnalDlgType(true);
        setOpen(true);
    }

    // События поля позывной
    const isCallSign = (fl) => {
        if (fl) {
            // Позывной был найден
            setCallSignOk(true);
            setisVisibleFullName(false);
            setStep(2);

            return;
        }

        // Позывной не был найден
        setCallSignOk(false);
        setisVisibleFullName(true);
        setStep(2);
    }
    const handleCallSignKeyUp = async (e) => {
        if (e.keyCode !== 13) return;

        handleNextClick();
    }
    const handleCallSignClick = () => {
        setCallSignOk(false);
        setStep(1);
    }
    const handleCallSign = async () => {
        let res = await FindByCallSignAsync(formik.values.callSign);
        // Валидация запроса
        if (!IsCode200(res)) {
            isCallSign(false);

            return;
        }

        setPersonnels(res.result);
        if (res.result.length === 1) {
            setSelectedValue(res.result);
            setFullNameValue(`${res.result[0].fullName}`);
            await handleFillData(res.result[0].id);
            isCallSign(true);

            return;
        }

        setPersonnalDlgType(false);
        setOpen(true);
    }

    const handleClick = () => {
        navigate(urlHome);
    }

    const handlerSetMilitaryPartList = (val) => {
        militaryPartList = val;
        setMilitaryPartJson(val);
        if (formik.values.militaryUnit.label === '') {
            setMilitaryPart(val[0]);
        }
    }
    const handleClickMilitaryPath = () => {
        setIsEditMilitaryPath(!isEditMilitaryPath);
    }

    const handlerSetMilitaryUnitList = (val) => {
        militaryUnitList = val;
        setMilitaryUnitJson(val);
        if (formik.values.division.label === '') {
            setMilitaryUnit(val[0]);
        }
    }
    const handleClickMilitaryUnit = () => {
        setIsEditMilitaryUnit(!isEditMilitaryUnit);
    }

    const handlerSetCompanyList = (val) => {
        militaryCompanyList = val;
        setMilitaryCompanyJson(val);
        if (formik.values.rota.label === '') {
            setMilitaryCompany(val[0]);
        }
    }
    const handleClickMilitaryCompany = () => {
        setIsEditMilitaryCompany(!isEditMilitaryCompany);
    }

    const handlerSetMilitaryPlatoonList = (val) => {
        militaryPlatoonList = val;
        setMilitaryPlatoonJson(val);
        if (formik.values.platoon.label === '') {
            setMilitaryPlatoon(val[0]);
        }
    }
    const handleClickMilitaryPlatoon = () => {
        setIsEditMilitaryPlatoon(!isEditMilitaryPlatoon);
    }

    const handlerSetMilitaryDepartmentList = (val) => {
        militaryDepartmentList = val;
        setMilitaryDepartmentJson(val);
        if (formik.values.department.label === '') {
            setMilitaryDepartment(val[0]);
        }
    }
    const handleClickMilitaryDepartment = () => {
        setIsEditMilitaryDepartment(!isEditMilitaryDepartment);
    }

    const handlerSetMilitaryRankList = (val) => {
        militaryRankList = val;
        setMilitaryRankJson(val);
        if (formik.values.actualRank.label === '') {
            setMilitaryRank(val[0]);
        }
    }
    const handleClickMilitaryRank = () => {
        setIsEditMilitaryRank(!isEditMilitaryRank);
    }

    const handlerSetLocationMedicalAssistanceList = (val) => {
        locationMedicalAssistanceList = val;
        setLocationMedicalAssistanceJson(val);
        if (formik.values.locationMedicalAssistance.label === '') {
            setLocationMedicalAssistance(val[0]);
        }
    }
    const handleClickLocationMedicalAssistance = () => {
        setIsEditLocationMedicalAssistance(!isEditLocationMedicalAssistance);
    }

    const handlerSetLocationWhereList = (val) => {
        locationWhereList = val;
        setLocationWhereJson(val);
        if (formik.values.locationWhere.label === '') {
            setLocationWhere(val[0]);
        }
    }
    const handleClickLocationWhere = () => {
        setIsEditLocationWhere(!isEditLocationWhere);
    }
    const handleChangeLocationWhere = (event, val) => {
        formik.setFieldValue(event, val);

        // если дата ещё не установлена, ставим текущую
        if (val !== undefined &&
            val?.label !== '-' &&
            val?.label !== ' ') {
            handleSetDateOfEvacuation(new Date().toISOString());

            return;
        }

        formik.setFieldValue(model.dateOfEvacuation.name, null);
    }
    const handleChangeLocationWhereEdit = (event) => {
        let val = event.target.value;
        formik.setFieldValue(model.locationWhere.textFld.name, val);
        if (val.trim() !== '-' &&
            val.trim() !== '') {
            handleSetDateOfEvacuation(new Date().toISOString());

            return;
        }

        formik.setFieldValue(model.dateOfEvacuation.name, null);
    }

    const handlerDataLoader = async () => {
        let handbook = await MilitaryHandbooksAsync();
        let handbook300 = await Handbook300Async();

        if (handbook.result) {
            handlerSetMilitaryPartList(handbook.result.militaryPart);
            handlerSetMilitaryUnitList(handbook.result.militaryUnit);
            handlerSetCompanyList(handbook.result.militaryCompany);
            handlerSetMilitaryPlatoonList(handbook.result.militaryPlatoon);
            handlerSetMilitaryDepartmentList(handbook.result.militaryDepartment);
            handlerSetMilitaryRankList(handbook.result.militaryRank);
        }
        if (handbook300.result) {
            handlerSetLocationMedicalAssistanceList(handbook300.result.locationWhere);
            handlerSetLocationWhereList(handbook300.result.locationWhere);
        }
    }

    const handleNextClick = async () => {
        setLoading(true);
        switch (step) {
            case 0:

                let tokenToCheck = formik.values.tokenNumber;
                tokenToCheck = tokenToCheck.replace(/\s/g, '');
                tokenToCheck = tokenToCheck.replace(/-/g, '');

                if (tokenToCheck.match(/^[а-яА-ЯёЁ]{1,2}-?\d{6}$/gmi)) {
                    let letters = tokenToCheck.slice(0, -6).toUpperCase();
                    let number = tokenToCheck.slice(-6, 8);
                    let fixedTokenNumber = letters + '-' + number;

                    formik.setFieldValue("tokenNumber", fixedTokenNumber);
                    formik.setErrors({ tokenNumber: null });

                } else {
                    formik.setErrors({ tokenNumber: "Неверный формат жетона (АА-999999)" });
                    break;
                }

                await handlerDataLoader();
                await handleTokenNumber();
                break;
            case 1:
                await handleCallSign();
                break;
            default:
                break;
        }
        setLoading(false);
    }

    const handleClose = async (value) => {
        setOpen(false);
        if (value === undefined) return;
        setSelectedValue(value);
        setFullNameValue(`${value.surname} ${value.name} ${value.patronymic}`);
        await handleFillData(value.id);
        personnalDlgType ? isTokenNumber(true) : isCallSign(true);
    };

    const handleFieldsPersonnelIsValid = () => {
        let tokenNumber =
            (formik.values.tokenNumber !== null &&
                formik.values.tokenNumber !== undefined &&
                formik.values.tokenNumber !== '' &&
                formik.values.tokenNumber !== ' ')
                ? true
                : false;
        let surname =
            (formik.values.surname !== null &&
                formik.values.surname !== undefined &&
                formik.values.surname !== '' &&
                formik.values.surname !== ' ')
                ? true
                : false;
        let name =
            (formik.values.name !== null &&
                formik.values.name !== undefined &&
                formik.values.name !== '' &&
                formik.values.name !== ' ')
                ? true
                : false;
        return (tokenNumber && surname && name) ? true : false;
    };

    // Добавление записи в журналы
    const handleRecordInsert = async () => {
        // Добавление записи в личный состав
        let idPersonnel = isVisibleFullName ? '' : selectedValue.id;
        if (isVisibleFullName) {
            // Валидация обязательных полей
            if (!handleFieldsPersonnelIsValid()) {
                isValid = false;
                alertInfo('Не все обязательные поля заполнены!');

                return;
            }

            let militaryPath = isEditMilitaryPath
                ? formik.values.militaryUnitEdit
                : formik.values.militaryUnit.label;
            let militaryUnit = isEditMilitaryUnit
                ? formik.values.divisionEdit
                : formik.values.division.label;
            let militaryCompany = isEditMilitaryCompany
                ? formik.values.rotaEdit
                : formik.values.rota.label;
            let militaryPlatoon = isEditMilitaryPlatoon
                ? formik.values.platoonEdit
                : formik.values.platoon.label;
            let militaryDepartment = isEditMilitaryDepartment
                ? formik.values.departmentEdit
                : formik.values.department.label;
            let militaryRank = isEditMilitaryRank
                ? formik.values.actualRankEdit
                : formik.values.actualRank.label;
            let personnel = {
                tokenNumber: formik.values.tokenNumber,
                callSign: formik.values.callSign,
                surname: formik.values.surname,
                name: formik.values.name,
                patronymic: formik.values.patronymic,
                birthday: new Date(formik.values.birthday).toISOString(),
                phoneNumber: formik.values.phoneNumber,
                militaryUnit: militaryPath,
                division: militaryUnit,
                rota: militaryCompany,
                platoon: militaryPlatoon,
                department: militaryDepartment,
                militaryRank: militaryRank,
            };
            let res = await RegPersonnelAsync(personnel.tokenNumber, personnel.callSign, personnel.surname, personnel.name,
                personnel.patronymic, personnel.birthday, personnel.phoneNumber, personnel.militaryUnit, personnel.division,
                personnel.rota, personnel.platoon, personnel.department, personnel.militaryRank);
            // Валидация запроса
            if (!IsCode200(res)) {
                if (IsCode503(res)) {
                    alertError('Сервис недоступен!');
                    isOnline = false;
                    setLoading(false);

                    return;
                };

                alertWarn('Не удалось создать запись!');
                setLoading(false);

                return;
            }

            idPersonnel = res.result;
        }

        let dateAction = dayjs(formik.values.dateAction).isValid()
            ? formik.values.dateAction
            : null;
        let dateOfEvacuation = dayjs(formik.values.dateOfEvacuation).isValid()
            ? formik.values.dateOfEvacuation
            : null;
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance.label ?? null;
        let locationWhere = isEditLocationWhere
            ? formik.values.locationWhereEdit
            : formik.values.locationWhere.label ?? null;
        let item = {
            idPersonnel: idPersonnel,
            dataOfVisits: dateAction,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            complaint: formik.values.complaint,
            anamnesis: formik.values.anamnesis,
            objectively: formik.values.objectively,
            diagnosis: formik.values.diagnosis,
            pharmacotherapy: formik.values.pharmacotherapy,
            recommendations: formik.values.recommendations,
            dateOfEvacuation: dateOfEvacuation,
            typeOfDirection: formik.values.typeOfDirection.label ?? null,
            specialist: formik.values.specialist.label ?? null,
            locationWhere: locationWhere ?? null,            
            therapy: formik.values.therapy,
        };
        let res = await RegTraumaAsync(item.idPersonnel, item.dataOfVisits, item.locationMedicalAssistance, item.complaint, item.anamnesis, 
            item.objectively, item.diagnosis, item.pharmacotherapy, item.recommendations, item.dateOfEvacuation, item.typeOfDirection, 
            item.specialist, item.locationWhere, item.therapy);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                isOnline = false;
                return;
            };
            
            alertWarn('Не удалось создать запись !');
            return;
        }

        alertSuccess('Запись успешно добавлена!');
    }

    // Обновление записи в журнале
    const handleRecordUpdate = async () => {
        let dateAction = formik.values.date === ''
            ? null
            : new Date(formik.values.dateAction).toISOString();
        let dateOfEvacuation = formik.values.dateOfEvacuation === ''
            ? null
            : new Date(formik.values.dateOfEvacuation).toISOString();
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance.label;
        let locationWhere = isEditLocationWhere
            ? formik.values.locationWhereEdit
            : formik.values.locationWhere.label;
        let item = {
            idEvent: idEvent,
            idHistory: idHistory,
            dateOfVisits: dateAction,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            complaint: formik.values.complaint,
            anamnesis: formik.values.anamnesis,
            objectively: formik.values.objectively,
            diagnosis: formik.values.diagnosis,
            pharmacotherapy: formik.values.pharmacotherapy,
            recommendations: formik.values.recommendations,
            dateOfEvacuation: dateOfEvacuation,
            typeOfDirection: formik.values.typeOfDirection.label ?? formik.values.typeOfDirection,
            specialist: formik.values.specialist.label ?? formik.values.specialist,
            locationWhere: locationWhere ?? null,
            therapy: formik.values.therapy,
        };
        var res = await UpdTraumaAsync(item.idEvent, item.idHistory, item.dateOfVisits, item.locationMedicalAssistance,
            item.complaint, item.anamnesis, item.objectively, item.diagnosis, item.pharmacotherapy, item.recommendations,
            item.dateOfEvacuation, item.typeOfDirection, item.specialist, item.locationWhere, item.therapy);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                isOnline = false;
                return;
            };
            alertWarn('Не удалось обновить запись !');
            return;
        }

        alertSuccess('Запись успешно обновлена!');
        setIsUpdate(false);
    }

    const handleRegistration = async () => {
        setLoading(true);
        isUpdate ? handleRecordUpdate() : handleRecordInsert();
        setLoading(false);
        console.log(isOnline);
        setTimeout(() => {
            if (isValid && isOnline) {
                navigate(urlHome)
            }

            isOnline = true;
        }, 600);
    }

    const handleFillData = async (id) => {
        if (isVisibleFullName) {
            return;
        }

        var res = await GetTraumaAsync(id);
        // Валидация запроса
        if (!IsCode200(res)) {
            handleSetDateOfEvacuation(null);

            return;
        }

        handleSetDataAction(res.result.dateOfVisits);
        handleSetDateOfEvacuation(res.result.dateOfEvacuation);

        let locationMedicalAssistanceItem = res.result.locationMedicalAssistance ?? '-';
        let elemLocationMedicalAssistance = locationMedicalAssistanceList.find(item => item.label === locationMedicalAssistanceItem);
        let locationWhereItem = res.result.locationWhere ?? '-';
        let elemLocationWhere = locationWhereList.find(item => item.label === locationWhereItem);
        let typeOfDirectionItem = res.result.typeOfDirection ?? '-';
        let elemTypeOfDirection = typeOfDirectionJson.find(item => item.label === typeOfDirectionItem);
        let specialist = res.result.specialist ?? '-';
        let elemSpecialist = specialistJson.find(item => item.label === specialist);

        formik.setFieldValue(model.locationMedicalAssistance.autoCompleteCbx.name, elemLocationMedicalAssistance ?? '');
        formik.setFieldValue(model.locationWhere.autoCompleteCbx.name, elemLocationWhere ?? '');

        formik.setFieldValue(model.typeOfDirection.name, elemTypeOfDirection.label);
        formik.setFieldValue(model.specialist.name, elemSpecialist.label);
        formik.setFieldValue(model.dateAction.name, res.result.dateOfVisits);
        formik.setFieldValue(model.complaint.name, res.result.complaint);
        formik.setFieldValue(model.anamnesis.name, res.result.anamnesis);
        formik.setFieldValue(model.objectively.name, res.result.objectively);
        formik.setFieldValue(model.diagnosis.name, res.result.diagnosis);
        formik.setFieldValue(model.therapy.name, res.result.therapy);
        formik.setFieldValue(model.pharmacotherapy.name, res.result.pharmacotherapy);
        formik.setFieldValue(model.recommendations.name, res.result.recommendations);

        setTypeOfDirection(elemTypeOfDirection);
        setSpecialist(elemSpecialist);
        setLocationMedicalAssistance(elemLocationMedicalAssistance);
        setLocationWhere(elemLocationWhere);

        setIsUpdate(res.result.idHistory === '00000000-0000-0000-0000-000000000000' ? false : true);
        setIdEvent(res.result.idEvent);
        setIdHistory(res.result.idHistory);

        return;
    }

    const handleFormAction = (values) => {
    };

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Травма',
        },
        birthday: {
            name: 'birthday',
        },
        dateAction: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateAction',
            label: 'Время обращения',
        },
        locationMedicalAssistance: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistanceEdit',
                label: 'Место оказания медицинской помощи',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationMedicalAssistance',
                label: 'Место оказания медицинской помощи',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        complaint: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'complaint',
            label: 'Жалобы',
            rows: '2',
        },
        anamnesis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'anamnesis',
            label: 'Анамнез',
            rows: '2',
        },
        objectively: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'objectively',
            label: 'Обьективный статус',
            rows: '2',
        },
        diagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'diagnosis',
            label: 'Диагноз',
            rows: '2',
        },
        pharmacotherapy: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'pharmacotherapy',
            label: 'Фармакотерапия',
            rows: '2',
        },
        therapy:{
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'therapy',
            label: 'Лечение',
            rows: '2',
        },
        recommendations: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'recommendations',
            label: 'Рекомендации',
            rows: '2',
        },
        dateOfEvacuation: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfEvacuation',
            label: 'Время выписки',
        },
        typeOfDirection: {
            thema: fields.collection2,
            size: 'small',
            name: 'typeOfDirection',
            label: 'Вид направления',
            variant: 'outlined',
            elems: typeOfDirectionJson,
        },
        specialist: {
            thema: fields.collection2,
            size: 'small',
            name: 'specialist',
            label: 'Специалист',
            variant: 'outlined',
            elems: specialistJson,
        },
        locationWhere: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhereEdit',
                label: 'Куда направляется',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhere',
                label: 'Куда направляется',
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        backBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClick,
            value: 'Назад',
        },
        nextBtn: {
            thema: buttons.collection1,
            icon: <MonitorHeartOutlinedIcon />,
            variant: 'contained',
            handleClick: handleNextClick,
            value: 'Далее',
        },
        insertBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Регистрация',
        },
        updateBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Обновление',
        },
        PersonnalDlg: {
            dialogTitle: {
                title1: 'Найденно по номеру жетона:',
                title2: 'Найденно по позывному:',
            },
            thema: dialogs.collection1
        },
    }
    let context = {
        tokenNumber: '',
        callSign: '',
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phoneNumber: '',
        militaryUnit: { id: '', label: '' },
        militaryUnitEdit: '',
        division: { id: '', label: '' },
        divisionEdit: '',
        rota: { id: '', label: '' },
        rotaEdit: '',
        platoon: { id: '', label: '' },
        platoonEdit: '',
        department: { id: '', label: '' },
        departmentEdit: '',
        actualRank: { id: '', label: '' },
        actualRankEdit: '',
        locationMedicalAssistance: { id: '', label: '' },
        locationMedicalAssistanceEdit: '',
        complaint: '',
        anamnesis: '',
        objectively: '',
        pharmacotherapy: '',
        diagnosis: '',
        therapy: '',
        recommendations: '',
        typeOfDirection: '',
        specialist: '',
        dateAction: null,
        dateOfEvacuation: null,
        locationWhere: { id: '', label: '' },
        locationWhereEdit: '',
    }
    const validationSchema = Yup.object().shape({

    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <Grid
                item xs={12} sm={12} md={12} lg={10} xl={10}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container spacing={2}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>

                            </Grid>
                            <TokenNumber
                                formik={formik} tokenNumberOk={tokenNumberOk}
                                handleKeyUp={handleTokenNumberKeyUp} handleClick={handleTokenNumberClick}
                            />
                            {step >= 1 ?
                                isVisibleCallSign ?
                                    <CallSign
                                        formik={formik} callSignOk={callSignOk}
                                        handleKeyUp={handleCallSignKeyUp} handleClick={handleCallSignClick}
                                    /> : <></> :
                                <></>
                            }
                            {step >= 2 ?
                                isVisibleFullName ?
                                    <FullNameInput
                                        formik={formik}
                                        militaryPartList={militaryPartJson} militaryPart={militaryPart} setMilitaryPart={setMilitaryPart} handleClickMilitaryPath={handleClickMilitaryPath} isEditMilitaryPath={isEditMilitaryPath}
                                        militaryUnitList={militaryUnitJson} militaryUnit={militaryUnit} setMilitaryUnit={setMilitaryUnit} handleClickMilitaryUnit={handleClickMilitaryUnit} isEditMilitaryUnit={isEditMilitaryUnit}
                                        militaryCompanyList={militaryCompanyJson} militaryCompany={militaryCompany} setMilitaryCompany={setMilitaryCompany} handleClickMilitaryCompany={handleClickMilitaryCompany} isEditMilitaryCompany={isEditMilitaryCompany}
                                        militaryPlatoonList={militaryPlatoonJson} militaryPlatoon={militaryPlatoon} setMilitaryPlatoon={setMilitaryPlatoon} handleClickMilitaryPlatoon={handleClickMilitaryPlatoon} isEditMilitaryPlatoon={isEditMilitaryPlatoon}
                                        militaryDepartmentList={militaryDepartmentJson} militaryDepartment={militaryDepartment} setMilitaryDepartment={setMilitaryDepartment} handleClickMilitaryDepartment={handleClickMilitaryDepartment} isEditMilitaryDepartment={isEditMilitaryDepartment}
                                        militaryRankList={militaryRankJson} militaryRank={militaryRank} setMilitaryRank={setMilitaryRank} handleClickMilitaryRank={handleClickMilitaryRank} isEditMilitaryRank={isEditMilitaryRank}
                                    /> :
                                    <FullNameShow
                                        fullNameValue={FullNameValue}
                                    /> :
                                <></>
                            }
                            {step >= 2 ? <>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={3} xl={3}
                                >
                                    <DateTimeDesktopFld
                                        thema={model.dateAction.thema} name={model.dateAction.name} size={model.dateAction.size}
                                        variant={model.dateAction.variant} label={model.dateAction.label}
                                        value={formik.values.dateAction}
                                        handleBlur={formik.handleBlur} handleChange={handleSetDataAction}
                                        touched={formik.touched.dateAction} error={formik.errors.dateAction}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={9} xl={9}
                                >
                                    <ComboBoxEdit
                                        model={model.locationMedicalAssistance} elems={locationMedicalAssistanceJson} value={formik.values.locationMedicalAssistance}
                                        valueEdit={formik.values.locationMedicalAssistanceEdit} val={locationMedicalAssistance} isEdit={isEditLocationMedicalAssistance}
                                        setFieldValue={formik.setFieldValue} handleSetState={setLocationMedicalAssistance} handleClick={handleClickLocationMedicalAssistance}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.locationMedicalAssistance} touchedEdit={formik.touched.locationMedicalAssistanceEdit}
                                        error={formik.errors.locationMedicalAssistance} errorEdit={formik.errors.locationMedicalAssistanceEdit}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.complaint.thema} size={model.complaint.size} type={model.complaint.type}
                                        variant={model.complaint.variant} name={model.complaint.name} label={model.complaint.label}
                                        rows={model.complaint.rows} value={formik.values.complaint}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.complaint} error={formik.errors.complaint}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.anamnesis.thema} size={model.anamnesis.size} type={model.anamnesis.type}
                                        variant={model.anamnesis.variant} name={model.anamnesis.name} label={model.anamnesis.label}
                                        rows={model.anamnesis.rows} value={formik.values.anamnesis}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.anamnesis} error={formik.errors.anamnesis}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.objectively.thema} size={model.objectively.size} type={model.objectively.type}
                                        variant={model.objectively.variant} name={model.objectively.name} label={model.objectively.label}
                                        rows={model.objectively.rows} value={formik.values.objectively}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.objectively} error={formik.errors.objectively}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.diagnosis.thema} size={model.diagnosis.size}
                                        type={model.diagnosis.type} variant={model.diagnosis.variant}
                                        name={model.diagnosis.name} label={model.diagnosis.label}
                                        rows={model.diagnosis.rows} value={formik.values.diagnosis}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.diagnosis} error={formik.errors.diagnosis}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.pharmacotherapy.thema} size={model.pharmacotherapy.size}
                                        type={model.pharmacotherapy.type} variant={model.pharmacotherapy.variant}
                                        name={model.pharmacotherapy.name} label={model.pharmacotherapy.label}
                                        rows={model.pharmacotherapy.rows} value={formik.values.pharmacotherapy}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.pharmacotherapy} error={formik.errors.pharmacotherapy}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <MultilineFld
                                        thema={model.therapy.thema} size={model.therapy.size}
                                        type={model.therapy.type} variant={model.therapy.variant}
                                        name={model.therapy.name} label={model.therapy.label}
                                        rows={model.therapy.rows} value={formik.values.therapy}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.therapy} error={formik.errors.therapy}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <MultilineFld
                                        thema={model.recommendations.thema} size={model.recommendations.size} type={model.recommendations.type}
                                        variant={model.recommendations.variant} name={model.recommendations.name} label={model.recommendations.label}
                                        rows={model.recommendations.rows} value={formik.values.recommendations}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                        touched={formik.touched.recommendations} error={formik.errors.recommendations}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={9} lg={9} xl={9}
                                >
                                    <ComboBoxEdit
                                        model={model.locationWhere} elems={locationWhereJson} value={formik.values.locationWhere}
                                        valueEdit={formik.values.locationWhereEdit} val={locationWhere} isEdit={isEditLocationWhere}
                                        setFieldValue={handleChangeLocationWhere} handleSetState={setLocationWhere} handleClick={handleClickLocationWhere}
                                        handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleChangeEdit={handleChangeLocationWhereEdit}
                                        touched={formik.touched.locationWhere} touchedEdit={formik.touched.locationWhere}
                                        error={formik.errors.locationWhere} errorEdit={formik.errors.locationWhere}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={3} lg={3} xl={3}
                                >
                                    <DateTimeDesktopFld
                                        thema={model.dateOfEvacuation.thema} name={model.dateOfEvacuation.name} size={model.dateOfEvacuation.size}
                                        variant={model.dateOfEvacuation.variant} label={model.dateOfEvacuation.label}
                                        value={formik.values.dateOfEvacuation}
                                        handleBlur={formik.handleBlur} handleChange={handleSetDateOfEvacuation}
                                        touched={formik.touched.dateOfEvacuation} error={formik.errors.dateOfEvacuation}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <AutoCompleteCbx
                                        thema={model.typeOfDirection.thema} name={model.typeOfDirection.name} size={model.typeOfDirection.size}
                                        elems={model.typeOfDirection.elems} variant={model.typeOfDirection.variant} label={model.typeOfDirection.label}
                                        value={formik.values.typeOfDirection} val={typeOfDirection}
                                        handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setTypeOfDirection}
                                        touched={formik.touched.typeOfDirection} error={formik.errors.typeOfDirection}
                                    />
                                </Grid>
                                <Grid
                                    item xs={12} sm={12} md={6} lg={6} xl={6}
                                >
                                    <AutoCompleteCbx
                                        thema={model.specialist.thema} name={model.specialist.name} size={model.specialist.size}
                                        elems={model.specialist.elems} variant={model.specialist.variant} label={model.specialist.label}
                                        value={formik.values.specialist} val={specialist}
                                        handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setSpecialist}
                                        touched={formik.touched.specialist} error={formik.errors.specialist}
                                    />
                                </Grid>
                            </> : <></>
                            }
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                <StartIconBtn
                                    thema={model.backBtn.thema} icon={model.backBtn.icon} variant={model.backBtn.variant}
                                    value={model.backBtn.value}
                                    handleClick={model.backBtn.handleClick}
                                />
                            </Grid>
                            <Grid
                                item xs={2} sm={2} md={8} lg={6} xl={6}
                            />
                            <Grid
                                item xs={5} sm={5} md={2} lg={3} xl={3}
                            >
                                {loading === true ?
                                    <CircularRightPrg /> :
                                    step === 2 ?
                                        isUpdate ?
                                            <EndIconBtn
                                                thema={model.updateBtn.thema} icon={model.updateBtn.icon} variant={model.updateBtn.variant}
                                                value={model.updateBtn.value}
                                                handleClick={model.updateBtn.handleClick}
                                            /> :
                                            <EndIconBtn
                                                thema={model.insertBtn.thema} icon={model.insertBtn.icon} variant={model.insertBtn.variant}
                                                value={model.insertBtn.value}
                                                handleClick={model.insertBtn.handleClick}
                                            /> :
                                        <EndIconBtn
                                            thema={model.nextBtn.thema} icon={model.nextBtn.icon} variant={model.nextBtn.variant}
                                            value={model.nextBtn.value}
                                            handleClick={model.nextBtn.handleClick}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <PersonnalDlg
                thema={model.PersonnalDlg.thema} dialogTitle={model.PersonnalDlg.dialogTitle} elems={personnels}
                selectedValue={selectedValue} open={open} onClose={handleClose} type={personnalDlgType}
            />
        </Grid>
    )
}
