let authentication = sessionStorage.getItem('authentication') ? JSON.parse(sessionStorage.getItem('authentication')) : {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}

/**
 * Класс модели запроса
 */
class RequestUserCheck {
    /**
     * Конструктор
     * @param { String } login пользователь
     * @param { String } password пароль
     */
    constructor(login, password) {
        this.login = login;
        this.password = password;
    }

    getModel() {
        return {
            login: this.login,
            password: this.password,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGet300 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestReg300 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстоятельства ранения
     * @param { String } nature Характер ранения
     * @param { String } gravityOfInjury Тяжесть ранения
     * @param { Date } dateOfVisits Дата и время поступления/обращения
     * @param { String } diagnosis Диагноз
     * @param { String } helpProvided Оказана помощь
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     
     */
    constructor(idPersonnel, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury, dateOfVisits, diagnosis,
        helpProvided, locationMedicalAssistance, recommendations, locationWhere, dateOfEvacuation) {
        this.idPersonnel = idPersonnel;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.gravityOfInjury = gravityOfInjury;
        this.dateOfVisits = dateOfVisits;
        this.diagnosis = diagnosis;
        this.helpProvided = helpProvided;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.recommendations = recommendations;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
            gravityOfInjury: this.gravityOfInjury,
            dateOfVisits: this.dateOfVisits,
            diagnosis: this.diagnosis,
            helpProvided: this.helpProvided,
            locationMedicalAssistance: this.locationMedicalAssistance,
            recommendations: this.recommendations,
            locationWhere: this.locationWhere,
            dateOfEvacuation: this.dateOfEvacuation,
            dateOfIssue100: null,
            locationWhere100: "",
            number98: "",
            history98: 0,
            dateOfIssue98: null,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestUpd300 {
    /**
     * Конструктор
     * @param { String } idEvent - УИН записи события
     * @param { String } IdHistory - УИН записи истории
     * 
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстоятельства ранения
     * @param { String } nature Характер ранения
     * @param { String } gravityOfInjury Тяжесть ранения
     *  
     * @param { Date } dateOfVisits Дата и время поступления/обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } helpProvided Оказана помощь
     * @param { String } diagnosis Диагноз
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     */
    constructor(idEvent, idHistory, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury, dateOfVisits,
        locationMedicalAssistance, helpProvided, diagnosis, recommendations, locationWhere, dateOfEvacuation
    ) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;

        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.gravityOfInjury = gravityOfInjury;

        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.helpProvided = helpProvided;
        this.diagnosis = diagnosis;
        this.recommendations = recommendations;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: {
                dateOfInjury: this.dateOfInjury,
                locationWhereInjury: this.locationWhereInjury,
                circumstances: this.circumstances,
                nature: this.nature,
                gravityOfInjury: this.gravityOfInjury,

                dateOfVisits: this.dateOfVisits,
                locationMedicalAssistance: this.locationMedicalAssistance,
                helpProvided: this.helpProvided,
                diagnosis: this.diagnosis,
                recommendations: this.recommendations,
                locationWhere: this.locationWhere,
                dateOfEvacuation: this.dateOfEvacuation,
            },
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGet200 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestReg200 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { Date } dateOfDeath Дата и время смерти
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства смерти
     * @param { String } nature Характер ранения
     * @param { String } diagnosis Паталогоанатомический диагноз
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     * @param { Date } deathCertificate Справка о смерти
     */
    constructor(idPersonnel, dateOfVisits, dateOfDeath, locationWhereInjury, circumstances, nature, diagnosis, locationWhere,
        dateOfEvacuation, deathCertificate
    ) {
        this.idPersonnel = idPersonnel;
        this.dateOfVisits = dateOfVisits;
        this.dateOfDeath = dateOfDeath;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.diagnosis = diagnosis;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
        this.deathCertificate = deathCertificate;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            dateOfVisits: this.dateOfVisits,
            dateOfDeath: this.dateOfDeath,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
            diagnosis: this.diagnosis,
            locationWhere: this.locationWhere,
            dateOfEvacuation: this.dateOfEvacuation,
            deathCertificate: this.deathCertificate,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestUpd200 {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { String } idHistory УИН записи истории
     * 
     * @param { Date } dateOfDeath Дата и время смерти
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства смерти
     * @param { String } nature Характер ранения
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } diagnosis Паталогоанатомический диагноз
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     * @param { String } deathCertificate Справка о смерти
     */
    constructor(idEvent, idHistory, dateOfVisits, dateOfDeath, locationWhereInjury, circumstances, nature, diagnosis, locationWhere,
        dateOfEvacuation, deathCertificate
    ) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.dateOfVisits = dateOfVisits;
        this.dateOfDeath = dateOfDeath;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.diagnosis = diagnosis;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
        this.deathCertificate = deathCertificate;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: {
                dateOfVisits: this.dateOfVisits,
                dateOfDeath: this.dateOfDeath,
                locationWhereInjury: this.locationWhereInjury,
                circumstances: this.circumstances,
                nature: this.nature,
                diagnosis: this.diagnosis,
                locationWhere: this.locationWhere,
                dateOfEvacuation: this.dateOfEvacuation,
                deathCertificate: this.deathCertificate,
            },
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGetDiseases {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 * @returns Модель ответа
 */
class RequestRegDiseases {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * @param { Date } dateOfVisits Дата и время обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy) {
        this.idPersonnel = idPersonnel;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            dateOfVisits: this.dateOfVisits,
            locationMedicalAssistance: this.locationMedicalAssistance,
            complaint: this.complaint,
            anamnesis: this.anamnesis,
            objectively: this.objectively,
            diagnosis: this.diagnosis,
            pharmacotherapy: this.pharmacotherapy,
            recommendations: this.recommendations,
            dateOfEvacuation: this.dateOfEvacuation,
            typeOfDirection: this.typeOfDirection,
            specialist: this.specialist,
            locationWhere: this.locationWhere,
            therapy: this.therapy,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestUpdDiseases {
    /**
     * Конструктор
     * @param { String } idEvent 
     * @param { String } idHistory
     * 
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere,
        therapy) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: {
                dateOfVisits: this.dateOfVisits,
                locationMedicalAssistance: this.locationMedicalAssistance,
                complaint: this.complaint,
                anamnesis: this.anamnesis,
                objectively: this.objectively,
                diagnosis: this.diagnosis,
                pharmacotherapy: this.pharmacotherapy,
                recommendations: this.recommendations,
                dateOfEvacuation: this.dateOfEvacuation,
                typeOfDirection: this.typeOfDirection,
                specialist: this.specialist,
                locationWhere: this.locationWhere,
                therapy: this.therapy,
            },            
            ...authParams,
        }
    }
}


/**
 * Класс модели запроса
 */
class RequestGetTrauma {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 * @returns Модель ответа
 */
class RequestRegTrauma {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * @param { Date } dateOfVisits Дата и время обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение 
     */
    constructor(idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere,
        therapy) {
        this.idPersonnel = idPersonnel;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            dateOfVisits: this.dateOfVisits,
            locationMedicalAssistance: this.locationMedicalAssistance,
            complaint: this.complaint,
            anamnesis: this.anamnesis,
            objectively: this.objectively,
            diagnosis: this.diagnosis,
            pharmacotherapy: this.pharmacotherapy,
            recommendations: this.recommendations,
            dateOfEvacuation: this.dateOfEvacuation,
            typeOfDirection: this.typeOfDirection,
            specialist: this.specialist,
            locationWhere: this.locationWhere,
            therapy: this.therapy,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestUpdTrauma {
    /**
     * Конструктор
     * @param { String } idEvent 
     * @param { String } idHistory
     * 
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere,
        therapy) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: {
                dateOfVisits: this.dateOfVisits,
                locationMedicalAssistance: this.locationMedicalAssistance,
                complaint: this.complaint,
                anamnesis: this.anamnesis,
                objectively: this.objectively,
                diagnosis: this.diagnosis,
                pharmacotherapy: this.pharmacotherapy,
                recommendations: this.recommendations,
                dateOfEvacuation: this.dateOfEvacuation,
                typeOfDirection: this.typeOfDirection,
                specialist: this.specialist,
                locationWhere: this.locationWhere,
                therapy: this.therapy,
            },
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestRegPersonnel {
    /**
     * Конструктор
     * @param { String } tokenNumber Номер жетона
     * @param { String } callSign Позывной
     * @param { String } surname Фамилия
     * @param { String } name Имя
     * @param { String } patronymic Отчество
     * @param { Date } birthday Дата рождения
     * @param { String } phoneNumber Номер телефона
     * @param { String } militaryUnit Воинская часть
     * @param { String } division Подразделение
     * @param { String } rota Рота
     * @param { String } platoon Взвод
     * @param { String } department Отделение
     * @param { String } militaryRank ВОенское звание
     */
    constructor(tokenNumber, callSign, surname, name, patronymic, birthday, phoneNumber, militaryUnit, division, rota, platoon,
        department, militaryRank
    ) {
        this.tokenNumber = tokenNumber;
        this.callSign = callSign;
        this.surname = surname;
        this.name = name;
        this.patronymic = patronymic;
        this.birthday = birthday;
        this.phoneNumber = phoneNumber;
        this.militaryUnit = militaryUnit;
        this.division = division;
        this.rota = rota;
        this.platoon = platoon;
        this.department = department;
        this.militaryRank = militaryRank;
    }

    getModel() {
        return {
            tokenNumber: this.tokenNumber,
            callSign: this.callSign,
            surname: this.surname,
            name: this.name,
            patronymic: this.patronymic,
            birthday: this.birthday,
            phoneNumber: this.phoneNumber,
            militaryUnit: this.militaryUnit,
            division: this.division,
            rota: this.rota,
            platoon: this.platoon,
            department: this.department,
            wus: this.militaryRank,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByTokenNumber {
    /**
     * Конструктор
     * @param { String } tokenNumber Номер жетона
     */
    constructor(tokenNumber) {
        this.tokenNumber = tokenNumber;
    }

    getModel() {
        return {
            tokenNumber: this.tokenNumber,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByCallSign {
    constructor(findByCallSign) {
        this.findByCallSign = findByCallSign;
    }

    getModel() {
        return {
            findByCallSign: this.findByCallSign,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestDocGet {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        };
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByString {
    constructor(value) {
        this.value = value;
    }

    getModel() {
        return {
            value: this.value,
            ...authParams,
        }
    }
}


export {
    // REST API Контроллер взаимодействия с биллингом
    RequestUserCheck,
    // REST API Контроллер взаимодействия с формами
    RequestGet300, RequestReg300, RequestUpd300, RequestGet200, RequestReg200, RequestUpd200, RequestGetDiseases,
    RequestRegDiseases, RequestUpdDiseases, RequestGetTrauma, RequestRegTrauma, RequestUpdTrauma,
    // REST API Контроллер взаимодействия с журналом личного состава
    RequestRegPersonnel, RequestFindByTokenNumber, RequestFindByCallSign, RequestFindByString,
    // REST API Контроллер для взаимодействия с документами
    RequestDocGet
}