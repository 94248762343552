import React from 'react';
import { useNavigate } from 'react-router-dom';

import { drawers } from '../app/thema';
import { urlHome, urlDocuments, urlSignOut, urlExport, urlHistory } from '../common/helper/UrlRouter';
import { ExportByAllToCsv } from '../common/helper/Export';
import { Drawer, Box, List } from '@mui/material';
import { DividerDwr, ItemListDwr, ItemCollapseListDwr } from '../components/Drawers';

import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


/**
 * Виджет левого меню
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function LeftMenu(props) {
    const navigate = useNavigate();


    // Раздел меню экспорта
    const SubExportMenu = (props) => {
        const handleExportCsvUtf8 = () => {
            props.handleClick();
            navigate(urlExport);
        }

        const model = {
            thema: drawers.collection1,
            divider: {
                guid: 'd7196533-9ac6-43ec-8857-8b75b2d2a544',
            },
            menu: [
                {
                    guid: 'd92e8f4f-8d7e-4be8-bd97-2fbc08427b44',
                    type: 'Item',
                    icon: <FileDownloadOutlinedIcon />,
                    text: 'Экспорт в CSV',
                    disabled: false,
                    handleClickItem: handleExportCsvUtf8,
                },
            ],
        };

        return (
            <>
                <DividerDwr
                    guid={model.divider.guid}
                />
                <List>
                    {model.menu.map((item) => {
                        return (
                            <ItemListDwr
                                key={item.guid}
                                guid={item.guid} thema={model.thema} icon={item.icon} text={item.text}
                                disabled={item.disabled}
                                handleClickItem={item.handleClickItem}
                            />)
                    })}
                </List>
            </>
        )
    }

    // Раздел меню экспорта
    const SubHistory = (props) => {
        const handleHistory = () => {
            props.handleClick();
            navigate(urlHistory);
        }

        const model = {
            thema: drawers.collection1,
            divider: {
                guid: 'd7196533-9ac6-43ec-8857-8b75b2d2a555',
            },
            menu: [
                {
                    guid: 'd92e8f4f-8d7e-4be8-bd97-2fbc08427b55',
                    type: 'Item',
                    icon: <PortraitOutlinedIcon />,
                    text: 'История Заболевания',
                    disabled: false,
                    handleClickItem: handleHistory,
                },
            ],
        };

        return (
            <>
                <DividerDwr
                    guid={model.divider.guid}
                />
                <List>
                    {model.menu.map((item) => {
                        return (
                            <ItemListDwr
                                key={item.guid}
                                guid={item.guid} thema={model.thema} icon={item.icon} text={item.text}
                                disabled={item.disabled}
                                handleClickItem={item.handleClickItem}
                            />)
                    })}
                </List>
            </>
        )
    }

    const handleLogoutClick = () => {
        sessionStorage.clear();
        props.handleClick();

        navigate(urlSignOut);
    }

    // Раздел меню подвал
    const SubFooterMenu = () => {
        const model = {
            thema: drawers.collection1,
            divider: {
                guid: '6207a29d-2a70-426b-8536-7c61e58c3e3d',
            },
            menu: [
                {
                    guid: '7dfcc4c9-01a8-4040-a1af-9609c4bc92d2',
                    type: 'Item',
                    icon: <LogoutOutlinedIcon />,
                    text: 'Выход',
                    disabled: false,
                    handleClickItem: handleLogoutClick,
                },
            ],
        };

        return (
            <>
                <DividerDwr
                    guid={model.divider.guid}
                />
                <List>
                    {model.menu.map((item) => {
                        return (
                            <ItemListDwr
                                key={item.guid}
                                guid={item.guid} thema={model.thema} icon={item.icon} text={item.text}
                                handleClickItem={item.handleClickItem}
                            />)
                    })}
                </List>
            </>
        )
    }
    

    const handlePatientRegistration = () => {
        props.handleClick();
        navigate(urlHome);
    }

    const handleDocuments = () => {
        props.handleClick();
        navigate(urlDocuments);
    }

    // Меню регистрации форм
    const SubRegistrationMenu = () => {
        const model = {
            thema: drawers.collection1,
            menu: [
                {
                    guid: '759b515b-35b5-4ec9-9f2f-ad787e11e6fc',
                    type: 'Item',
                    icon: <PendingActionsOutlinedIcon />,
                    text: 'Регистрация пациента',
                    disabled: false,
                    handleClickItem: handlePatientRegistration,
                },
            ],
        }

        return (
            <List>
                {model.menu.map((item) => {
                    return (
                        <ItemListDwr
                            key={item.guid}
                            guid={item.guid} thema={model.thema} icon={item.icon} text={item.text}
                            handleClickItem={item.handleClickItem} disabled={item.disabled}
                        />)
                })}
            </List>
        )
    }


    // Раздел меню для роли администратор
    const SubAdminMenu = (props) => {
        return (
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Медицинский взвод
    const SubMedicalPlatoon = (props) => {
        return (
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Медицинская рота
    const SubMedicalCompany = (props) => {
        return(
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Медицинский батальен
    const SubMedicalBattalion = (props) => {
        return(
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Военный госпиталь
    const SubMilitaryHospital = (props) => {
        return(
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Начмед корпуса
    const SubNachmedCorpus = (props) => {
        return(
            <>
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Разделы меню у роли Начмед округа
    const SubNachmedRegion = (props) => {
        return(
            <>
                <SubHistory handleClick={props.handleClick} />
                <SubExportMenu handleClick={props.handleClick} />
            </>
        )
    }

    // Раздел меню у роли Медицинский регистратор
    const SubMedicalRegistrator = (props) => {
        return(
            <>
                <SubRegistrationMenu handleClick={props.handleClick} />
                <SubHistory handleClick={props.handleClick} />
            </>
        )
    }


    return (
        <Drawer
            open={props.open} onClose={props.handleClick}
        >
            <Box
                sx={{
                    width: 300
                }}
                role='presentation'
            >
                {(() => {
                    switch (props.role) {
                        case 'admin':
                            return <SubAdminMenu handleClick={props.handleClick} />
                        case 'medicalPlatoon':
                            return <SubMedicalPlatoon handleClick={props.handleClick} />
                        case 'medicalCompany':
                            return <SubMedicalCompany handleClick={props.handleClick} />
                        case 'medicalBattalion':
                            return <SubMedicalBattalion handleClick={props.handleClick} />
                        case 'militaryHospital':
                            return <SubMilitaryHospital handleClick={props.handleClick} />
                        case 'nachmedCorpus':
                            return <SubNachmedCorpus handleClick={props.handleClick} />
                        case 'nachmedRegion':
                            return <SubNachmedRegion handleClick={props.handleClick} />

                        case 'medicalRegistrator':
                            return <SubMedicalRegistrator handleClick={props.handleClick} />
                        default:
                            break;
                    }
                })()}

                <SubFooterMenu />
            </Box>
        </Drawer>
    )
}