import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled } from '@mui/material/styles';

import {
    Typography,
} from '@mui/material';


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';




import {
    selectPatientData,
} from "../../historySlice";


// const Item = styled(Typography)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     margin: "0 !important",
//     // padding: theme.spacing(1),
//     textAlign: 'right',
//     color: theme.palette.text.secondary,
// }));

// const SEARCHTEST = "рвота, тошнота, боль в животе, боль в левом подреберье, гипертрофия миокарда правого желудочка (ГПЖ), стеноз легочного ствола подклапанный,  дефект межжелудочковой перегородки";

const OtherDiseasesComponent = ({ currentEvent, onChangeCurrentEvent }) => {
    // const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    // const [value, setValue] = useState(currentValue || null);

    const patientData = useSelector(selectPatientData);

    let { events } = patientData;

    events = events && events.length ? events : [];

    console.log(currentEvent);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickItem = (index) => {
        onChangeCurrentEvent(index);
    };

    useEffect(() => {

    }, []);

    // patientInfo: {
    //     id: "123323",
    //     tokenNumber: "226749",
    //     dataBirth: "12.04.1988",
    //     fio: "Петров Василий Иванович",
    //     dateOfInjury: "20.06.2024",
    //     locationWhereInjury: "г. Белград, село Новые Старики",
    //     nature: "осколочное ранение",
    //     division: "Подразделение",
    //     militaryRank: "Рядовой",
    //     doljnost: "Должность",
    //   },

    console.log(patientData.patientInfo);

    // const { patientInfo, evacuations } = patientData;
    return <>

        <List
            sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"

        >

            <ListItemButton onClick={handleClick}>

                <ListItemText primary={`Все ранения/заболевания (${events.length})`} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {events.map((event, index) => {
                        let title = "";

                        if (event.history && event.history[0]) {
                            if (event.history[0].hospitalizationDate) {
                                title = event.history[0].hospitalizationDate ? new Date(event.history[0].hospitalizationDate).toLocaleDateString() : "";
                            }

                            if (title && event.history[0].disease) {
                                title = title + " - " + event.history[0].disease;
                            } else {
                                title = event.history[0].disease;
                            }
                        }

                        return <ListItemButton
                            // disabled={index === currentEvent}
                            key={index}
                            sx={{
                                pl: 4,
                                fontWeight: index === currentEvent ? "700" : "700",
                            }}
                            onClick={() => handleClickItem(index)}
                        >
                            <ListItemIcon>
                                {index === currentEvent ? <StarIcon /> : <StarBorderIcon />}
                            </ListItemIcon>
                            <ListItemText
                                primary={title}
                                sx={{
                                    pl: 4,
                                }}
                                primaryTypographyProps={{
                                    sx: {
                                        pl: 4,
                                        fontWeight: index === currentEvent ? "700" : "500",
                                    }
                                }}
                            />
                        </ListItemButton>;
                    })}

                </List>
            </Collapse>
        </List>
    </>;
}

export const OtherDiseases = memo(OtherDiseasesComponent);
