import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';


const repeatTable = ({patientData,currentHistoryItem}={}) => <table className="table">
    <tbody>
        <tr>
            <td className="bborder-flex bborder2">
                <div className="btitle">Дата и время осмотра:</div>
                <div className="bcontent"></div>
            </td>
        </tr>
        <tr>
            <td className=""></td>
        </tr>
        <tr>
            <td className="bborder-flex bborder2">
                <div className="btitle">Жалобы:</div>
                <div className="bcontent"></div>
            </td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className=""></td>
        </tr>
        <tr>
            <td className="bborder-flex bborder2">
                <div className="btitle">Объективный статус:</div>
                <div className="bcontent"></div>
            </td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className=""></td>
        </tr>
        <tr>
            <td className="bborder-flex bborder2">
                <div className="btitle">Перевязка:</div>
                <div className="bcontent"></div>
            </td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className=""></td>
        </tr>
        <tr>
            <td className="bborder-flex bborder2">
                <div className="btitle">Назначения:</div>
                <div className="bcontent"></div>
            </td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
        <tr>
            <td className="bborder2"></td>
        </tr>
    </tbody>
</table>;



const PrintTpl_3Component = ({ printData }) => {


    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString() : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString() : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    let currentEventData = patientData.events[currentEvent] || {};
    let currentDate = new Date().toLocaleString();


    return <>


        <div className="page print">
            <style type="text/css" media="print">
                {" @page { size: landscape; } "}
                {" body { height: 200mm; width: 297mm; "}
                {" .page { height: 200mm; width: 297mm;} "}
            </style>
            <table className="table">
                <tbody>
                    <tr>
                        <td className="half bleft">

                            {repeatTable({patientData,currentHistoryItem})}

                        </td>
                        <td className="bright">




                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">Медицинская рота войсковой части 01069 (н. п. Красный Октябрь)</td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">Амбулаторная карта №{currentEventData.idXls} от {currentDate}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle btitle-w">ФИО:</div>
                                            <div className="bcontent">{patientData.fio}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">

                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td className="bflex">
                                                            <div className="btitle btitle-w">Дата рождения:</div>
                                                            <div className="bcontent">{patientData.birthday}</div>
                                                        </td>
                                                        <td className="bflex">
                                                            <div className="btitle btitle-w phone" >Номер телефона:</div>
                                                            <div className="bcontent">{patientData.phone || ""}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>






                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle btitle-w">Личный номер:</div>
                                            <div className="bcontent">{patientData.suid}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle btitle-w">Воинское звание:</div>
                                            <div className="bcontent">{patientData.militaryRank}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle btitle-w">Должность:</div>
                                            <div className="bcontent">{patientData.militaryFunction}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle btitle-w">В/ч, подразделение:</div>
                                            <div className="bcontent">{patientData.militaryUnit}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Диагноз при поступлении:</td>
                                    </tr>
                                    <tr>
                                        <td className="bpadd">{currentHistoryItem.disease}</td>
                                    </tr>
          
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex bborder2">
                                            <div className="btitle">Диагноз клинический:</div>
                                            <div className="bcontent"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder2"></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder2"></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="bborder-flex noborder">
                                            <div class="btitle">Лечащий врач:</div>
                                            <div class="bcontent">{currentHistoryItem.doctor}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>




                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div className="page print">
            <table className="table">
                <tbody>
                    <tr>
                        <td className="half bleft">
                            {repeatTable()}
                        </td>
                        <td className="bright">
                            {repeatTable()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="page print">
            <table className="table">
                <tbody>
                    <tr>
                        <td className="half bleft">
                            {repeatTable()}
                        </td>
                        <td className="bright">
                            {repeatTable()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="page print">
            <table className="table">
                <tbody>
                    <tr>
                        <td className="half bleft">
                            {repeatTable()}
                        </td>
                        <td className="bright">
                            {repeatTable()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
}

export const PrintTpl_3 = memo(PrintTpl_3Component);
