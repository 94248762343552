import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
    Collapse,
    ListItemIcon,
} from '@mui/material';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import PrintIcon from '@mui/icons-material/Print';
import ArrowRightIcon from '@mui/icons-material/Assignment';

import {
    selectCurrentHistoryItem,
    setCurrentHistoryItem,
    selectPatientData,
} from "../../historySlice";

import { PrintTpl_1 as PrintTpl1 } from "../PrintTpl_1";
import { PrintTpl_2 as PrintTpl2 } from "../PrintTpl_2";
import { PrintTpl_3 as PrintTpl3 } from "../PrintTpl_3";
import { PrintTpl_4 as PrintTpl4 } from "../PrintTpl_4/PrintTpl_4";

import "./styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const titles = [
    {
        key: "status",
        value: "Статус",
        type: ""
    },
    {
        key: "location",
        value: "Место оказания медицинской помощи",
        type: ""
    },
    {
        key: "hospitalizationDate",
        value: "Время поступления/обращения",
        type: "date"
    },
    {
        key: "disease",
        value: "Диагноз",
        type: ""
    },
    {
        key: "helping",
        value: "Оказана помощь",
        type: ""
    },
    {
        key: "dischargeDate",
        value: "Время эвакуации/выписки",
        type: "date"
    },
    {
        key: "evacuationLocation",
        value: "Куда эвакуирован",
        type: ""
    },
];
const theme = createTheme({
    palette: {
        primary: { main: "#344C11" },
    },
});



const HistoryItemComponent = ({ currentEvent, notEnded, isLast }) => {

    const dispatch = useDispatch();
    const currentHistoryItem = useSelector(selectCurrentHistoryItem);
    const patientData = useSelector(selectPatientData);
    const [tplId, setTplId] = useState(0);

    const [openPrint, setOpenPrint] = React.useState(false);
    console.log(patientData);
    console.log(currentHistoryItem);
    console.log(currentEvent);
    console.log(notEnded);
    console.log(isLast);

    let fullPrint = false;
    if (notEnded === false && isLast === true) {
        fullPrint = true;
        console.log("fullPrint", fullPrint);
    }


    /**
     * shitfix
     * @description нужно добавить проверку. В условия к fullPrint нужно добавить проверку. Печатать можно всё, если не прошло 24 часа. А пока хардкодим всегда true
     */
    fullPrint = true;


    const handleClose = () => {
        dispatch(setCurrentHistoryItem({}));
    };
    const handleClickPrintMenu = () => {
        setOpenPrint(!openPrint);
    };


    let printData = {
        patientData,
        currentHistoryItem,
        currentEvent
    };

    const handleClickPrint = (tpl) => {
        setTplId(tpl);

        setTimeout(() => {
            window.print();
        }, 600);

        console.log("handleClickPrint");

        // var printContents = <div>test</div>;
        // var originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // document.body.innerHTML = originalContents;

    }

    // useEffect(() => {
    //     if (tplId !== 0) {
    //         window.print();
    //     }
    // }, [tplId]);

    return <>

        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Dialog
                    fullScreen
                    open={!!currentHistoryItem.id}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    {tplId === 1 ? <PrintTpl1 printData={printData} /> : null}
                    {tplId === 2 ? <PrintTpl2 printData={printData} /> : null}
                    {tplId === 3 ? <PrintTpl3 printData={printData} /> : null}
                    {tplId === 4 ? <PrintTpl4 printData={printData} /> : null}
                    <div className="no-print">
                        <AppBar
                            sx={{ position: 'relative' }}
                        >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {/* --- */}
                                </Typography>
                                {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}
                            </Toolbar>
                        </AppBar>
                        <List>

                            {
                                titles.map((title, i) => <>
                                    <ListItemButton key={i}>
                                        <ListItemText
                                            primary={title.value}
                                            secondary={title.type === "date" && currentHistoryItem[title.key] ? new Date(currentHistoryItem[title.key]).toLocaleString() : currentHistoryItem[title.key] || "-"}
                                        />
                                    </ListItemButton>
                                    <Divider />
                                </>)
                            }
                            <ListItem>

                                <List>
                                    <ListItemButton selected
                                        onClick={handleClickPrintMenu}
                                    >
                                        <ListItemIcon>
                                            <PrintIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Печать"
                                            sx={{
                                                pr: 2,
                                            }}
                                        />
                                        {openPrint ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={openPrint} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton
                                                sx={{ pl: 4 }}
                                                onClick={() => handleClickPrint(3)}
                                            >
                                                <ListItemIcon>
                                                    <ArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Амбулаторная карта" />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ pl: 4 }}
                                                onClick={() => handleClickPrint(4)}
                                            >
                                                <ListItemIcon>
                                                    <ArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Стационарный лист" />
                                            </ListItemButton>

                                            {fullPrint && <ListItemButton
                                                sx={{ pl: 4 }}
                                                onClick={() => handleClickPrint(2)}
                                            >
                                                <ListItemIcon>
                                                    <ArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Переводной эпикриз" />
                                            </ListItemButton>}

                                            {fullPrint && <ListItemButton
                                                sx={{ pl: 4 }}
                                                onClick={() => handleClickPrint(1)}
                                            >
                                                <ListItemIcon>
                                                    <ArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Выписной эпикриз" />
                                            </ListItemButton>
                                            }

                                        </List>
                                    </Collapse>
                                </List>


                            </ListItem>

                            <Divider />

                        </List>
                    </div>
                </Dialog>
            </ThemeProvider>
        </React.Fragment>
    </>;
}

export const HistoryItem = memo(HistoryItemComponent);
