// REST API Контроллер взаимодействия с формами
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
} from '../api/QueryHandler';
import {
    Get300POST, Reg300POST, Upd300POST, Get200POST, Reg200POST, Upd200POST,
    GetDiseasesPOST, RegDiseasesPOST, UpdDiseasesPOST, GetTraumaPOST, RegTraumaPOST, 
    UpdTraumaPOST
} from '../helper/UrlController';
import {
    ResponseAlert, ItemForma300, ItemForma200, ItemFormaDiseases, ItemFormaTrauma,
} from '../models/Response';
import {
    RequestGet300, RequestReg300, RequestUpd300, RequestGet200, RequestReg200, RequestUpd200, 
    RequestGetDiseases, RequestRegDiseases, RequestUpdDiseases, RequestGetTrauma, RequestRegTrauma, 
    RequestUpdTrauma
} from '../models/Request';


/**
 * API для получения формы 300
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function Get300Async(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestGet300(idPersonnel).getModel();
        let res = await PostFetchAsync(Get300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }
        model.alert = new ResponseAlert(res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level,
            res.alert.sticky
        ).getModel();
        model.result = new ItemForma300(
            res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances,
            res.result.nature, res.result.gravityOfInjury, res.result.idHistory, res.result.stateByEvacuation,
            res.result.locationMedicalAssistance, res.result.dateOfVisits, res.result.diagnosis, res.result.helpProvided,
            res.result.recommendations, res.result.locationWhere, res.result.dateOfEvacuation
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для регистрации формы 300
 * @param { String } idPersonnel Уникальный идентификационный номер записи из журнала личного состава
 * @param { Date } dateOfInjury Дата и время ранения
 * @param { String } locationWhereInjury Где получил ранение
 * @param { String } circumstances Обстоятельства ранения/смерти/болезни
 * @param { String } nature Характер ранения
 * @param { string } gravityOfInjury Тяжесть ранения
 * @param { Date } dateOfVisits Дата и время поступления/обращения
 * @param { String } diagnosis Диагноз
 * @param { String } helpProvided Оказана помощь
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } recommendations Рекомендации/назначения
 * @param { String } locationWhere Куда эвакуирован
 * @param { Date } dateOfEvacuation Дата и время эвакуации 
 * @returns Модель ответа
 */
async function Reg300Async(idPersonnel, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury,
    dateOfVisits, diagnosis, helpProvided, locationMedicalAssistance, recommendations, locationWhere,
    dateOfEvacuation) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestReg300(idPersonnel, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury,
            dateOfVisits, diagnosis, helpProvided, locationMedicalAssistance, recommendations, locationWhere, dateOfEvacuation
        ).getModel();
        let res = await PostFetchAsync(Reg300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления формы 300
 * @param { String } idEvent - УИН записи события
 * @param { String } idHistory - УИН записи истории
 * @param { Date } dateOfInjury Дата и время ранения
 * @param { String } locationWhereInjury Где получил ранение
 * @param { String } circumstances Обстоятельства ранения
 * @param { String } nature Характер ранения
 * @param { String } gravityOfInjury Тяжесть ранения
 * @param { Date } dateOfVisits Дата и время поступления/обращения
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } helpProvided Оказана помощь
 * @param { String } diagnosis Диагноз
 * @param { String } recommendations Рекомендации при выписке
 * @param { String } locationWhere Куда эвакуирован
 * @param { Date } dateOfEvacuation Дата и время эвакуации
 * @returns Модель ответа
 */
async function Upd300Async(idEvent, idHistory, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury,
    dateOfVisits, locationMedicalAssistance, helpProvided, diagnosis, recommendations, locationWhere, dateOfEvacuation
) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpd300(idEvent, idHistory, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury,
            dateOfVisits, locationMedicalAssistance, helpProvided, diagnosis, recommendations, locationWhere, dateOfEvacuation
        ).getModel();
        let res = await PostFetchAsync(Upd300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 * API для получения формы 200
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
async function Get200Async(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestGet200(idPersonnel).getModel();
        let res = await PostFetchAsync(Get200POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemForma200(res.result.idEvent, res.result.dateOfDeath, res.result.locationWhereInjury,
            res.result.circumstances, res.result.nature, res.result.idHistory, res.result.stateByEvacuation,
            res.result.dateOfVisits, res.result.diagnosis, res.result.locationWhere, res.result.dateOfEvacuation,
            res.result.deathCertificate
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления формы 200
 * @param { String } idPersonnel Уникальный идентификационный номер записи из журнала личного состава
 * @param { Date } dateOfDeath Дата и время смерти
 * @param { String } locationWhereInjury Где получил ранение
 * @param { String } circumstances Обстоятельства смерти
 * @param { String } nature Характер ранения
 * @param { Date } dateOfVisits Дата и время поступления
 * @param { String } diagnosis Диагноз на этапе первой врачебной помощи
 * @param { String } locationWhere Куда эвакуирован
 * @param { Date } dateOfEvacuation Дата и время эвакуации
 * @param { String } deathCertificate Справка о смерти
 * 
 * @returns Модель ответа
 */
async function Reg200Async(idPersonnel, dateOfDeath, locationWhereInjury, circumstances, nature, dateOfVisits, diagnosis,
    locationWhere, dateOfEvacuation, deathCertificate
) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestReg200(idPersonnel, dateOfVisits, dateOfDeath, locationWhereInjury, circumstances, nature, diagnosis,
            locationWhere, dateOfEvacuation, deathCertificate
        ).getModel();
        let res = await PostFetchAsync(Reg200POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления формы 200
 * @param { String } idEvent УИН записи события
 * @param { String } idHistory УИН записи истории
 * @param { Date } dateOfDeath Дата и время смерти
 * @param { String } locationWhereInjury Где получил ранение
 * @param { String } circumstances Обстаятельства смерти
 * @param { String } nature Характер ранения
 * @param { Date } dateOfVisits Дата и время поступления
 * @param { String } diagnosis Паталогоанатомический диагноз
 * @param { String } locationWhere Куда эвакуирован
 * @param { Date } dateOfEvacuation Дата и время эвакуации
 * @param { String } deathCertificate Справка о смерти
 * @returns Модель ответа
 */
async function Upd200Async(idEvent, idHistory, dateOfDeath, locationWhereInjury, circumstances, nature, dateOfVisits,
    diagnosis, locationWhere, dateOfEvacuation, deathCertificate
) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpd200(idEvent, idHistory, dateOfVisits, dateOfDeath, locationWhereInjury, circumstances, nature,
            diagnosis, locationWhere, dateOfEvacuation, deathCertificate
        ).getModel();
        let res = await PostFetchAsync(Upd200POST().url, req);

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 API для получения формы заболеваний
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
async function GetDiseasesAsync(idPersonnel) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestGetDiseases(idPersonnel).getModel();
        let res = await PostFetchAsync(GetDiseasesPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemFormaDiseases(
            res.result.idEvent, res.result.idHistory, res.result.dateOfVisits, res.result.locationMedicalAssistance, 
            res.result.complaint, res.result.anamnesis, res.result.objectively, res.result.diagnosis, res.result.pharmacotherapy, 
            res.result.recommendations, res.result.dateOfEvacuation, res.result.typeOfDirection, res.result.specialist,
            res.result.locationWhere, res.result.therapy
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для регистрации заболеваний
 * @param { String } idPersonnel Уникальный идентификационный номер записи из журнала личного состава
 * @param { Date } dateOfVisits Дата и время обращения
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } complaint Жалобы
 * @param { String } anamnesis Анамнез
 * @param { String } objectively Обьективный статус
 * @param { String } diagnosis Диагноз
 * @param { String } pharmacotherapy Фармакотерапия
 * @param { String } recommendations Рекомендации при выписке
 * @param { Date } dateOfEvacuation Дата и время выписки
 * @param { String } typeOfDirection Вид направления
 * @param { String } specialist Специалист
 * @param { String } locationWhere Куда направлен
 * @param { String } therapy Лечение
 * @returns Модель ответа
 */
async function RegDiseasesAsync(idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
    diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestRegDiseases(
            idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively, diagnosis, pharmacotherapy,
            recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
        ).getModel();
        let res = await PostFetchAsync(RegDiseasesPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления формы 200
 * @param { String } idEvent УИН записи события
 * @param { String } idHistory УИН записи истории
 * @param { Date } dateOfVisits Дата и время обращения
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } complaint Жалобы
 * @param { String } anamnesis Анамнез
 * @param { String } objectively Обьективный статус
 * @param { String } diagnosis Диагноз
 * @param { String } pharmacotherapy Фармакотерапия
 * @param { String } recommendations Рекомендации при выписке
 * @param { Date } dateOfEvacuation Дата и время выписки
 * @param { String } typeOfDirection Вид направления
 * @param { String } specialist Специалист
 * @param { String } locationWhere Куда направлен
 * @param { String } therapy Лечение
 * @returns Модель ответа
 */
async function UpdDiseasesAsync(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
    diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpdDiseases(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, 
            objectively, diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist,
            locationWhere, therapy
        ).getModel();
        let res = await PostFetchAsync(UpdDiseasesPOST().url, req);
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 API для получения формы заболеваний
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
 async function GetTraumaAsync(idPersonnel) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestGetTrauma(idPersonnel).getModel();
        let res = await PostFetchAsync(GetTraumaPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemFormaTrauma(
            res.result.idEvent, res.result.idHistory, res.result.dateOfVisits, res.result.locationMedicalAssistance, 
            res.result.complaint, res.result.anamnesis, res.result.objectively, res.result.diagnosis, res.result.pharmacotherapy, 
            res.result.recommendations, res.result.dateOfEvacuation, res.result.typeOfDirection, res.result.specialist,
            res.result.locationWhere, res.result.therapy
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для регистрации заболеваний
 * @param { String } idPersonnel Уникальный идентификационный номер записи из журнала личного состава
 * @param { Date } dateOfVisits Дата и время обращения
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } complaint Жалобы
 * @param { String } anamnesis Анамнез
 * @param { String } objectively Обьективный статус
 * @param { String } diagnosis Диагноз
 * @param { String } pharmacotherapy Фармакотерапия
 * @param { String } recommendations Рекомендации при выписке
 * @param { Date } dateOfEvacuation Дата и время выписки
 * @param { String } typeOfDirection Вид направления
 * @param { String } specialist Специалист
 * @param { String } locationWhere Куда направлен
 * @param { String } therapy Лечение
 * @returns Модель ответа
 */
async function RegTraumaAsync(idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
    diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestRegTrauma(
            idPersonnel, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively, diagnosis, pharmacotherapy,
            recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
        ).getModel();
        let res = await PostFetchAsync(RegTraumaPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления формы 200
 * @param { String } idEvent УИН записи события
 * @param { String } idHistory УИН записи истории
 * 
 * @param { Date } dateOfVisits Дата и время обращения
 * @param { String } locationMedicalAssistance Место оказания медицинской помщи
 * @param { String } complaint Жалобы
 * @param { String } anamnesis Анамнез
 * @param { String } objectively Обьективный статус
 * @param { String } diagnosis Диагноз
 * @param { String } pharmacotherapy Фармакотерапия
 * @param { String } recommendations Рекомендации при выписке
 * @param { Date } dateOfEvacuation Дата и время выписки
 * @param { String } typeOfDirection Вид направления
 * @param { String } specialist Специалист
 * @param { String } locationWhere Куда направлен
 * @param { String } therapy Лечение
 * @returns Модель ответа
 */
async function UpdTraumaAsync(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
    diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere, therapy
) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpdTrauma(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, 
            objectively, diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, 
            locationWhere, therapy
        ).getModel();
        let res = await PostFetchAsync(UpdTraumaPOST().url, req);
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    Get300Async, Reg300Async, Upd300Async, Get200Async, Reg200Async, Upd200Async, GetDiseasesAsync, RegDiseasesAsync,
    UpdDiseasesAsync, GetTraumaAsync, RegTraumaAsync, UpdTraumaAsync
}