import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    urlDef, urlSignIn, urlSignOut,
    urlHome, 
    urlPatientRegistration, urlForma200, urlForma300, urlFormaDiseases, urlFormaTrauma,
    urlDocuments, urlGetTransferableEpicrisis, urlGetMilitaryDischarge, urlGetPatientCard, urlGetStationarySheet,
    urlExport,
    urlHistory,
} from '../common/helper/UrlRouter';

import PAuthentication from '../pages/PAuthentication';
import PMain from '../pages/PMain';
import PRegistration from '../pages/PRegistration';
import PDocuments from '../pages/PDocuments';
import PExport from '../pages/PExport';
import PHistory from '../pages/PHistory';


/**
 * Маршрутизация приложения
 * @returns готовая страница
 */
export default function router() {
    return (
        <Routes>
            <Route path={urlDef} element={<PAuthentication />} />
            <Route path={urlSignIn} element={<PAuthentication />} />
            <Route path={urlSignOut} element={<PAuthentication />} />

            <Route path={urlHome} element={<PMain />} />
            <Route path={urlPatientRegistration} element={<PRegistration step={0} />} />
            <Route path={urlFormaDiseases} element={<PRegistration step={10} />} />
            <Route path={urlFormaTrauma} element={<PRegistration step={20} />} />
            <Route path={urlForma200} element={<PRegistration step={200} />} />
            <Route path={urlForma300} element={<PRegistration step={300} />} />

            <Route path={urlDocuments} element={<PDocuments step={0} />} />
            <Route path={urlGetTransferableEpicrisis} element={<PDocuments step={1} />} />
            <Route path={urlGetMilitaryDischarge} element={<PDocuments step={2} />} />
            <Route path={urlGetPatientCard} element={<PDocuments step={3} />} />
            <Route path={urlGetStationarySheet} element={<PDocuments step={4} />} />

            <Route path={urlExport} element={<PExport />} />
            <Route path={urlHistory} element={<PHistory />} />
        </Routes>
    )
}