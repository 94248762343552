import { API_URLS } from "../../constants";
import { POST } from "../../helpers";


const MOCKUP = [
  { id: 111, name: "Иван Петров (жетон 33456)" },
  { id: 222, name: "Пётр Иванов (жетон 5456)" }
];

let authentication = sessionStorage.getItem('authentication') ? JSON.parse(sessionStorage.getItem('authentication')) : {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}

export function fetchSendSearch({ data = {}, success = null }) {
  let authentication = JSON.parse(sessionStorage.getItem('authentication'));

  return POST({
    path: API_URLS.main.CommonSearch,
    data: {
      key: authentication.key,
      authkey: authentication.authkey,
      value: data.text,
      event: 300,
      ...data,
    },
    success: (result) => {
      // let searchResult = result && result.concepts && result.concepts.length > 0 ? result.concepts : [];
      let searchResult = result && result.items && result.items.length > 0 ? result.items : []; // MOCKUP
      console.log(searchResult);
      success && success(searchResult);
    },
  });
}

export function fetchSendGetRAS({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.SubmitRAS,
    data,
    success: () => {
      success && success();
    },
  });
}

export function fetchSendGetPatientData({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.GetPatientData,
    data: {
      ...data,
            // id: "c2b8cdaf-bec5-4a7b-b24c-57ecd1567881",
      id: data.id,
      ...authParams,
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchSendCheckParametricData({ data = {}, success = null }) {
  console.log(data);
  console.log(success);
  return POST({
    path: API_URLS.main.CheckParametricData,
    data,
    success: () => {
      success && success();
    },
  });
}

export function fetchSendGetDiagnostic({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.SubmitSymptoms,
    data: {
      key: "",
      login: "",
      authkey: data.authKey,
      // guid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      patient: data.patient,
      settings: data.settings,
      questions: data.questions,
      includeGraph: false,
      symptoms: {
        formalized: data.formalizedSymptoms,
        unitsList: data.unitsList,
        freeText: ""
      },
      properties: null
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchGetSymptomsFromText({ data = {} }) {
  return POST({
    path: API_URLS.main.GetSymptomsFromText,
    data: {
      authkey: data.authKey,
      text: data.text,
      type: data.type,
    },
  });
}
