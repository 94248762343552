// Аутентификация пользователя
const urlDef = `/`;
const uriSession = `/session`;
const urlSignIn = `${uriSession}/signIn`;
const urlSignOut = `${uriSession}/signOut`;

// Домашняя страница
const urlHome = `/home`;

// Журнал приема
const urlPatientRegistration = `/registration`;
// Регистрация больного
const urlForma200 = `${urlPatientRegistration}/forma200`;
const urlForma300 = `${urlPatientRegistration}/forma300`;
const urlFormaDiseases = `${urlPatientRegistration}/formaDiseases`;
const urlFormaTrauma = `${urlPatientRegistration}/formaTrauma`;

// Документы
const urlDocuments = `/documents`;
const urlGetTransferableEpicrisis = `${urlDocuments}/GetTransferableEpicrisis`;
const urlGetMilitaryDischarge = `${urlDocuments}/GetMilitaryDischarge`;
const urlGetPatientCard = `${urlDocuments}/GetPatientCard`;
const urlGetStationarySheet = `${urlDocuments}/GetStationarySheet`;


// Журнал личного состава
const urnJournalOfPersonnel = `/journalOfPersonnel`;

// Экспорт
const urlExport = `/export`;

// История заболеваний
const urlHistory = `/history`;


export{
    urlDef, urlSignIn, urlSignOut, urlHome, urlPatientRegistration, urlForma200, urlForma300, urlFormaDiseases, urlFormaTrauma,
    urlDocuments, urlGetTransferableEpicrisis, urlGetMilitaryDischarge, urlGetPatientCard, urlGetStationarySheet,
    urnJournalOfPersonnel, urlExport, urlHistory,
}