import { date } from "yup";

/**
 * Класс модели уведомлений
 */
class ResponseAlert {
    /**
     * Конструктор
     * @param { String } code Код ошибки
     * @param { Number } actionCode Код ошибки API обработки самого запроса
     * @param { String } title Заголовок
     * @param { String } message Описание ошибки
     * @param { String } level Тип сообщения варианты: error-ошибка, success-успех, info-информация, danger-опасность
     * @param { Boolean } sticky Должно ли сообщение само пропасть
     */
    constructor(code, actionCode, title, message, level, sticky) {
        this.code = code;
        this.actionCode = actionCode;
        this.title = title;
        this.message = message;
        this.level = level;
        this.sticky = sticky;
    }

    getModel() {
        return {
            code: this.code,
            actionCode: this.actionCode,
            title: this.title,
            message: this.message,
            level: this.level,
            sticky: this.sticky,
        };
    }

    getCode500() {
        return {
            code: '500',
            actionCode: this.actionCode,
            title: 'Internal Server Error',
            message: 'Внутренняя ошибка сервера',
            level: this.level,
            sticky: this.sticky,
        };
    }
}

/**
 * Класс модели ответа
 */
class ResponseUserCheck {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН
     * @param { String } login Логин
     * @param { String } password Пароль
     * @param { String } role Роль пользовательского интерфейса
     */
    constructor(idPersonnel, login, password, role, key, authkey) {
        this.idPersonnel = idPersonnel;
        this.login = login;
        this.password = password;
        this.role = role;
        this.key = key;
        this.authkey = authkey;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            login: this.login,
            password: this.password,
            role: this.role,
            key: this.key,
            authkey: this.authkey,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemForma300 {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства ранения
     * @param { String } nature Характер ранения
     * @param { String } gravityOfInjury Тяжесть ранения
     * @param { String } idHistory УИН записи истории
     * @param { Number } stateByEvacuation Этап эвакуации
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } diagnosis Диагноз
     * @param { String } helpProvided Оказана помощь
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда направлен
     * @param { Date } dateOfEvacuation Дата и время эвакуации/выписки
     */
    constructor(idEvent, dateOfInjury, locationWhereInjury, circumstances, nature, gravityOfInjury,
        idHistory, stateByEvacuation, locationMedicalAssistance, dateOfVisits, diagnosis,
        helpProvided, recommendations, locationWhere, dateOfEvacuation
    ) {
        this.idEvent = idEvent;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.gravityOfInjury = gravityOfInjury;
        this.idHistory = idHistory;
        this.stateByEvacuation = stateByEvacuation;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.dateOfVisits = dateOfVisits;
        this.diagnosis = diagnosis;
        this.helpProvided = helpProvided;
        this.recommendations = recommendations;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
            gravityOfInjury: this.gravityOfInjury,
            idHistory: this.idHistory,
            stateByEvacuation: this.stateByEvacuation,
            locationMedicalAssistance: this.locationMedicalAssistance,
            dateOfVisits: this.dateOfVisits,
            diagnosis: this.diagnosis,
            helpProvided: this.helpProvided,
            recommendations: this.recommendations,
            locationWhere: this.locationWhere,
            dateOfEvacuation: this.dateOfEvacuation,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemForma200 {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи
     * @param { Date } dateOfDeath Дата и время смерти
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства смерти
     * @param { String } nature Характер ранения
     * @param { String } idHistory УИН записи журнала истории
     * @param { Number } stateByEvacuation Этап эвакуации
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } diagnosis Паталогоанатомический диагноз
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     * @param { Date } deathCertificate Справка о смерти
     */
    constructor(idEvent, dateOfDeath, locationWhereInjury, circumstances, nature, idHistory, stateByEvacuation,
        dateOfVisits, diagnosis, locationWhere, dateOfEvacuation, deathCertificate
    ) {
        this.idEvent = idEvent;
        this.dateOfDeath = dateOfDeath;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
        this.idHistory = idHistory;
        this.stateByEvacuation = stateByEvacuation;
        this.dateOfVisits = dateOfVisits;
        this.diagnosis = diagnosis;
        this.locationWhere = locationWhere;
        this.dateOfEvacuation = dateOfEvacuation;
        this.deathCertificate = deathCertificate;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfDeath: this.dateOfDeath,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
            idHistory: this.idHistory,
            stateByEvacuation: this.stateByEvacuation,
            dateOfVisits: this.dateOfVisits,
            diagnosis: this.diagnosis,
            locationWhere: this.locationWhere,
            dateOfEvacuation: this.dateOfEvacuation,
            deathCertificate: this.deathCertificate,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemFormaDiseases {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи
     * @param { String } idHistory УИН записи истории
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время эвакуации/выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere,
        therapy
    ) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            dateOfVisits: this.dateOfVisits,
            locationMedicalAssistance: this.locationMedicalAssistance,
            complaint: this.complaint,
            anamnesis: this.anamnesis,
            objectively: this.objectively,
            diagnosis: this.diagnosis,
            pharmacotherapy: this.pharmacotherapy,
            recommendations: this.recommendations,
            dateOfEvacuation: this.dateOfEvacuation,
            typeOfDirection: this.typeOfDirection,
            specialist: this.specialist,
            locationWhere: this.locationWhere,
            therapy: this.therapy,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemFormaTrauma {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи
     * @param { String } idHistory УИН записи истории
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время эвакуации/выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, dateOfVisits, locationMedicalAssistance, complaint, anamnesis, objectively,
        diagnosis, pharmacotherapy, recommendations, dateOfEvacuation, typeOfDirection, specialist, locationWhere,
        therapy
    ) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.dateOfVisits = dateOfVisits;
        this.locationMedicalAssistance = locationMedicalAssistance;
        this.complaint = complaint;
        this.anamnesis = anamnesis;
        this.objectively = objectively;
        this.diagnosis = diagnosis;
        this.pharmacotherapy = pharmacotherapy;
        this.recommendations = recommendations;
        this.dateOfEvacuation = dateOfEvacuation;
        this.typeOfDirection = typeOfDirection;
        this.specialist = specialist;
        this.locationWhere = locationWhere;
        this.therapy = therapy;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            dateOfVisits: this.dateOfVisits,
            locationMedicalAssistance: this.locationMedicalAssistance,
            complaint: this.complaint,
            anamnesis: this.anamnesis,
            objectively: this.objectively,
            diagnosis: this.diagnosis,
            pharmacotherapy: this.pharmacotherapy,
            recommendations: this.recommendations,
            dateOfEvacuation: this.dateOfEvacuation,
            typeOfDirection: this.typeOfDirection,
            specialist: this.specialist,
            locationWhere: this.locationWhere,
            therapy: this.therapy,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemFindByPersonnel {
    /**
     * Конструктор
     * @param { String } id УИН записи
     * @param { String } fullName Шифрованное поле ФИО
     */
    constructor(id, fullName) {
        this.id = id;
        this.fullName = fullName;
    }

    getModel() {
        return {
            id: this.id,
            fullName: this.fullName,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemFindByString {
    /**
     * Конструктор
     * @param { String } id УИН записи
     * @param { String } tokenNumber Номер жетона
     * @param { String } surname Фамилия
     * @param { String } name Имя
     * @param { String } patronymic Отчество
     * @param { Date } birthday Дата рождения
     * @param { String } phoneNumber Номер телефона
     * @param { String } militaryUnit УИН записи из воинских частей
     * @param { String } division УИН записи из картотеки подразделений
     * @param { String } rota УИН записи из картотеки рота
     * @param { String } platoon УИН записи из картотеки взводов
     * @param { String } department УИН записи из картотеки отделение
     * @param { String } positionCode Код должности
     * @param { String } positionByState УИН записи из картотеки должностей по штату
     * @param { String } militaryRankByState УИН записи из картотеки воинских званий по штату
     * @param { String } wus № ВУС
     * @param { String } actualRank УИН записи из картотеки фактических званий
     */
    constructor(id, tokenNumber, surname, name, patronymic, birthday, phoneNumber, militaryUnit, division, rota,
        platoon, department, positionCode, positionByState, militaryRankByState, wus, actualRank
    ){
        this.id = id;
        this.tokenNumber = tokenNumber;
        this.surname = surname;
        this.name = name;
        this.patronymic = patronymic;
        this.birthday = birthday;
        this.phoneNumber = phoneNumber;
        this.militaryUnit = militaryUnit;
        this.division = division;
        this.rota = rota;
        this.platoon = platoon;
        this.department = department;
        this.positionCode = positionCode;
        this.positionByState = positionByState;
        this.militaryRankByState = militaryRankByState;
        this.wus = wus;
        this.actualRank = actualRank;
    }

    getModel() {
        return {
            id: this.id,
            tokenNumber: this.tokenNumber,
            surname: this.surname,
            name: this.name,
            patronymic: this.patronymic,
            birthday: this.birthday,
            phoneNumber: this.phoneNumber,
            militaryUnit: this.militaryUnit,
            division: this.division,
            rota: this.rota,
            platoon: this.platoon,
            department: this.department,
            positionCode: this.positionCode,
            positionByState: this.positionByState,
            militaryRankByState: this.militaryRankByState,
            wus: this.wus,
            actualRank: this.actualRank,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemHandbookByFullName {
    /**
     * Конструктор
     * @param { Object } militaryPart Список военских частей
     * @param { Object } militaryUnit Список подразделений
     * @param { Object } militaryCompany Список рот
     * @param { Object } militaryPlatoon Список взводов
     * @param { Object } militaryDepartment Cписок отделений
     * @param { Object } militaryRank Список воинских званий
     * @param { Object } militaryWus Список № ВУС
     */
    constructor(militaryPart, militaryUnit, militaryCompany, militaryPlatoon, militaryDepartment, militaryRank, militaryWus) {
        this.militaryPart = militaryPart;
        this.militaryUnit = militaryUnit;
        this.militaryCompany = militaryCompany;
        this.militaryPlatoon = militaryPlatoon;
        this.militaryDepartment = militaryDepartment;
        this.militaryRank = militaryRank;
        this.militaryWus = militaryWus;
    }

    getModel() {
        return {
            militaryPart: this.militaryPart,
            militaryUnit: this.militaryUnit,
            militaryCompany: this.militaryCompany,
            militaryPlatoon: this.militaryPlatoon,
            militaryDepartment: this.militaryDepartment,
            militaryRank: this.militaryRank,
            militaryWus: this.militaryWus,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemHandbookLocation {
    /**
     * 
     * @param { Object } locationWhereInjury
     * @param {  Object } locationWhere 
     */
    constructor(locationWhereInjury, locationWhere) {
        this.locationWhereInjury = locationWhereInjury;
        this.locationWhere = locationWhere;
    }

    getModel() {
        return {
            locationWhereInjury: this.locationWhereInjury,
            locationWhere: this.locationWhere
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemEventTransferableEpicrisis {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Место ранения
     * @param { String } circumstances Обстаятельства ранения
     * @param { Object } transferableEpicrisis Список истории болезни
     */
    constructor(idEvent, dateOfInjury, locationWhereInjury, circumstances, transferableEpicrisis) {
        this.idEvent = idEvent;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.transferableEpicrisis = transferableEpicrisis;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            transferableEpicrisis: this.transferableEpicrisis,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemTransferableEpicrisis {
    /**
     * Конструктор
     * @param { String } idHistory УИН записи истории
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { Date } dateOfEvacuation Дата и время эвакуации/выписки
     * @param { String } helpProvided Лечение
     * @param { String } diagnosis Диагноз
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда направлен
     */
    constructor(idHistory, locationMedicalAssistance, dateOfVisits, dateOfEvacuation, helpProvided, diagnosis, 
        recommendations, locationWhere) {
            this.idHistory = idHistory;
            this.locationMedicalAssistance = locationMedicalAssistance;
            this.dateOfVisits = dateOfVisits;
            this.dateOfEvacuation = dateOfEvacuation;
            this.helpProvided = helpProvided;
            this.diagnosis = diagnosis;
            this.recommendations = recommendations;
            this.locationWhere = locationWhere;
    }

    getModel(){
        return {
            idHistory: this.idHistory,
            locationMedicalAssistance: this.locationMedicalAssistance,
            dateOfVisits: this.dateOfVisits,
            dateOfEvacuation: this.dateOfEvacuation,
            helpProvided: this.helpProvided,
            diagnosis: this.diagnosis,
            recommendations: this.recommendations,
            locationWhere: this.locationWhere,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemEventMilitaryDischarge {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Место ранения
     * @param { String } circumstances Обстаятельства ранения
     * @param { Object } transferableEpicrisis Список истории болезни
     */
    constructor(idEvent, dateOfInjury, locationWhereInjury, circumstances, transferableEpicrisis) {
        this.idEvent = idEvent;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.transferableEpicrisis = transferableEpicrisis;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            transferableEpicrisis: this.transferableEpicrisis,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemEventPatientCard {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Место ранения
     * @param { String } circumstances Обстаятельства ранения
     * @param { String } nature Характер ранения
     */
    constructor(idEvent, dateOfInjury, locationWhereInjury, circumstances, nature) {
        this.idEvent = idEvent;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
        }
    }
}

/**
 * Класс модели ответа
 */
class ItemEventStationarySheet{
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Место ранения
     * @param { String } circumstances Обстаятельства ранения
     * @param { String } nature Характер ранения
     */
    constructor(idEvent, dateOfInjury, locationWhereInjury, circumstances, nature) {
        this.idEvent = idEvent;
        this.dateOfInjury = dateOfInjury;
        this.locationWhereInjury = locationWhereInjury;
        this.circumstances = circumstances;
        this.nature = nature;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            dateOfInjury: this.dateOfInjury,
            locationWhereInjury: this.locationWhereInjury,
            circumstances: this.circumstances,
            nature: this.nature,
        }
    }
}


export {
    ResponseAlert, ResponseUserCheck, ItemForma300, ItemForma200, ItemFormaDiseases, ItemFindByPersonnel, ItemFindByString,
    ItemHandbookByFullName, ItemHandbookLocation, ItemFormaTrauma, ItemEventTransferableEpicrisis, ItemTransferableEpicrisis,
    ItemEventMilitaryDischarge, ItemEventPatientCard, ItemEventStationarySheet,
}